import React, { useEffect, useState } from 'react';
import './CourrierScan.scss';
import { Card, CardBody, CardTitle, CardSubtitle, CardFooter, CardHeader, CardImg } from 'shards-react';
import { FormInput, Button } from 'shards-react';


//3241281363
function ScanInfo() {
    const fetchedHTML = "https://us-central1-guatnit123.cloudfunctions.net/app/dhl-api/"

    const initialVal = {
        estimatedDelivery: 'No disponible',
        estimatedTime: 'No disponible',
        status: 'No disponible',
        events: [],
        statusTimestamp: 'No disponible',
        fetched: false,
        origin: 'No disponible',
        destination: 'No disponible'
    };

    const [searchID, setSearchID] = useState('- -');
    const [submit, setSubmit] = useState(false);
    const [parsed, setParsed] = useState(initialVal);

    const responseParser = (x) => {
        const pkg = x.body.shipments[0];
        setParsed({
            estimatedDelivery: pkg.estimatedTimeOfDelivery,
            estimatedTime: pkg.estimatedTimeOfDeliveryRemark,
            status: pkg.status.description,
            statusTimestamp: pkg.status.statusTimestamp,
            events: pkg.events,
            fetched: true,
            origin: pkg.origin.address.addressLocality,
            destination: pkg.destination.address.addressLocality
        })
    }

    useEffect(() => {
        fetch(fetchedHTML + String(searchID),
            {
                method: 'get',
            }
        ).then(x => x.json())
            .then(x => {
                responseParser(x);
                console.log("DHL response", x)
            }).catch(e => {
                setParsed(initialVal);
                console.log("ERROR", e)
            })
    }, [submit])

    return (
        <>
            <div className="main-box">
                <h4>Búsqueda DHL</h4>
                <h5>Ingresa acá tu número de rastreo de DHL</h5>
                <h5>y podrás ver el estado de tu paquete.</h5>
                <FormInput
                    className="input"
                    type="number"
                    size="lg"
                    value={searchID}
                    onChange={(e) => {
                        setSearchID(e.target.value);
                    }}
                />
                <Button
                    className="submit-btn"
                    onClick={() => {
                        setSubmit(!submit);
                    }}
                >
                    Buscar
            </Button>
               { parsed.fetched && <div className="card-box">
                    <Card className="status-card">
                        <CardHeader><h5>Entrega programada:</h5>{parsed.estimatedDelivery}{' '}{parsed.estimatedTime}</CardHeader>
                        <CardBody>
                            {parsed.events.map(x => <><h5>{x.description}</h5><h6>{x.timestamp.split('T').join(' ')}</h6><p>- -</p></>)}
                        </CardBody>
                        <CardFooter><h5>{parsed.origin}</h5><h5>{parsed.destination}</h5></CardFooter>
                    </Card>
                </div>}
            </div>
        </>
    )
}

export default ScanInfo;

