import "firebase/database";
import "firebase/auth";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyAcCtzvRGCUQJ4smMF14uKmelpYmGW6zTU",
  authDomain: "fir-finallyjuan.firebaseapp.com",
  databaseURL: "https://fir-finallyjuan.firebaseio.com",
  projectId: "firebasefinallyjuan",
  storageBucket: "firebasefinallyjuan.appspot.com",
  messagingSenderId: "33788123767",
  appId: "1:33788123767:web:fd20df98a6aad97c810472"
};

// const config = {
//   apiKey: "AIzaSyDHiakBp7FrkJsaOiGCqM7CTDIhTnpyEwk",
//   authDomain: "listosoftware.firebaseapp.com",
//   projectId: "listosoftware",
//   storageBucket: "listosoftware.appspot.com",
//   messagingSenderId: "194384334024",
//   appId: "1:194384334024:web:d59b0a92f6fe4c0affa9fd",
//   measurementId: "G-GKRY46YBRM"
// };


export default config;